import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { graphql, Link } from 'gatsby';
// import Hero from '../../components/shared/hero';
import SectionTitles from '../../components/shared/section-titles';
// import constants from '../../constants/about';
import BottomCta from '../../components/shared/bottom-cta';
import ScrollAnimation from 'react-animate-on-scroll';
import ShareTab from '../../components/shared/share-tab';
// import styled from 'styled-components';

import '../../styles/common.css';
// import '../../styles/landing.css';

import ImageSection from '../../components/home/image-section';

export default ({ data }) => {
	return (
		<Layout activeTab={'/zashto-netsurf/'}>
			<SEO
				title="Интернет и ТВ | Промоционални цени от NetSurf | 0882991619"
				description="Интернет скорости до 300Mbit/s и цифрова телевизия с над 225+ канала. Предплати за 2 месеца и ползвай 4. Включи се с до 15% отстъпка от месечната си такса."
				meta={[
					{
						name: `robots`,
						content: `noindex`
					}
				]}
			/>
			<style
				dangerouslySetInnerHTML={{
					__html: `
        .top-nav,
        .navbar-toggler,
        .navbar .navbar-collapse,
        #pcta-header
        {
          display: none !important;
        }
      `
				}}
			/>
			<div style={{ paddingTop: '16rem' }}>
				<SectionTitles>
					<h1>Смятай лесно с нас</h1>
					<hr className="center" />
				</SectionTitles>
			</div>

			<div className="container">
				<div className="row d-flex">
					<div className="col-12 col-lg-6">
						<iframe
							width="100%"
							height="100%"
							src="https://www.youtube.com/embed/UxMnSubbvzM"
							frameborder="0"
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
						{/* <img src={data.mainImage.childImageSharp.fluid.src} className='img-fluid mb-4' alt='Смятай лесно с нас' /> */}
					</div>
					<div className="col-12 col-lg-6">
						<p>
							Смятай с нас. Няма да те оценяваме, а ще подаряваме безплатни
							месеци пакетна услуга интернет и телевизия.
						</p>
						<p>
							Ще кажеш, че не сме прави, но ние можем да те убедим, че 2=4.
							Как? Много лесно - плащаш 2 месеца, получаваш още 2 безплатно
							от нас + до 15% отстъпка
						</p>
						<p>
							Не е необходимо посещение в наш магазин. Обади се, за да ти
							обясним математиката на NetSurf.
						</p>
						<p className="text-center mt-5">
							<a href="tel:+359882991619" className="btn btn-primary green">
								088 299 1619
							</a>
						</p>
					</div>
				</div>

				<div className="row d-flex mb-4">
					<div className="col-12">
						<p className="mt-4">
							<strong>Условия:</strong>
						</p>
						<ul className="mb-5 text-left blue-ticks">
							<li>
								Стани клиент на NetSurf и заяви пакетна услуга интернет и
								интерактивна телевизия.
							</li>
						</ul>

						<p>
							<strong>Времеви обхват:</strong>
						</p>
						<ul className="mb-5 text-left blue-ticks">
							<li>10 април - 10 май 2020 година</li>
						</ul>

						<p>
							<strong>Промоции:</strong>
						</p>
						<ul className="mb-5 text-left blue-ticks">
							<li>
								2=4, заплати първите си две такси накуп и ще получиш 2
								месеца безплатна услуга
							</li>
							<li>
								Получи до 15% отстъпка от месечната си такса за целия срок
								на договора
							</li>
						</ul>

						<p>
							<em>
								*В промоцията участват пакетите{' '}
								<Link to={'/za-doma/'}>“​Голям пакет”​</Link> и{' '}
								<Link to={'/za-doma/'}>“​Пълна Програма”​</Link>.
							</em>
						</p>
					</div>
				</div>

				<ShareTab path="/landing-pages/smyatai-lesno-s-nas" />
			</div>

			<ImageSection />

			<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
				<BottomCta bgColor={'transparent'}>
					<h2 className="mb-4 d-none d-md-block">
						Нямаме търпение да ти покажем
						<br />
						<span className="text-purple"> колко сме добри!</span>
					</h2>
					<h2 className="mb-4 d-block d-md-none">
						Нямаме търпение да ти покажем
						<span className="text-purple"> колко сме добри!</span>
					</h2>
					<p className="mb-5">
						Нашите пакети – по-благи от тези под коледната елха
					</p>
					<Link
						to={'/za-doma/'}
						className="btn btn-primary green"
						id="pcta-footer"
					>
						Виж пакетите
					</Link>
				</BottomCta>
			</ScrollAnimation>
		</Layout>
	);
};

export const query = graphql`
	query LandingSmyataiLesnoPage {
		mainImage: file(relativePath: { eq: "landing-pages/smyatai-lesno-s-nas.png" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
`;
